import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDataStats } from '../../hooks/useDataStats';
import { useSortableData } from '../../hooks/useSortableData';
import { formatNumber, cleanPagePath } from '../../utils/dataProcessing';
import { useTheme } from '../../auth/AuthContext';
import { graphColors } from '../../Utilities';
import LoadingSpinner from '../Layouts/LoadingSpinner';
import { getTooltip, useTooltip } from '../../config/tooltips';
import Tooltip from '../Layouts/Tooltip';
import { MetricCard } from '../Layouts/ChartTemplates';
import { LineChartTemplate } from '../Layouts/ChartTemplates';
import {
    metricsConfig,
    displayedChartMetrics,
    transformGSCData,
    transformKeywordsData,
    transformPagesData,
    tableColumns
} from '../../config/GSCConfig';

const GSC = ({
    data,
    yearOverYearData,
    previousPeriodData,
    customPeriodData,
    loadingState,
    pdfRefs,
    isPDFLayout,
    siteUrl
}) => {
    // Track selected metrics for the line chart
    const [selectedMetrics, setSelectedMetrics] = useState(['impressions']);
    const [hasData, setHasData] = useState(false);
    const [keywordsData, setKeywordsData] = useState({ byDate: {}, aggregated: { impressions: [], clicks: [] } });
    const [pagesData, setPagesData] = useState({ byDate: {}, aggregated: { impressions: [], clicks: [] } });
    const [activeTooltip, setActiveTooltip] = useState(null);
    const queryTooltip = getTooltip('gsc', 'topQueries');
    const pagesTooltip = getTooltip('gsc', 'topPages');
    const rankingTooltip = getTooltip('gsc', 'highestRanking');

    const theme = useTheme();
    const lineColors = useMemo(() => graphColors(theme), [theme]);

    useEffect(() => {
        if (data?.keywords?.rows && Array.isArray(data.keywords.rows)) {
            setHasData(true);
            setKeywordsData(transformKeywordsData(data.keywords));

            if (data?.pages?.rows && Array.isArray(data.pages.rows)) {
                setPagesData(transformPagesData(data.pages));
            }
        } else {
            setHasData(false);
        }
    }, [data]);

    if (loadingState?.isLoading) {
        return <LoadingSpinner />;
    }

    const transformedData = useMemo(() => {
        if (!data) return {};
        return transformGSCData(data, yearOverYearData, previousPeriodData, customPeriodData);
    }, [data, yearOverYearData, previousPeriodData, customPeriodData]);

    const { sortedData: chartData } = useSortableData(transformedData.chartData || [], {
        key: 'date',
        direction: 'asc'
    });

    const stats = useDataStats(
        data || {},
        yearOverYearData || {},
        previousPeriodData || {},
        customPeriodData || {},
        metricsConfig
    );

    const getLineConfig = useCallback(() => {
        const colorKeys = ['first', 'second', 'third', 'fourth'];

        return {
            type: 'line',
            height: 450,
            lines: selectedMetrics.map((metric, index) => ({
                dataKey: metric,
                name: metricsConfig[metric]?.getLabel?.() ?? metricsConfig[metric]?.label ?? metric,
                color: lineColors[colorKeys[index % colorKeys.length]],
                strokeWidth: 2,
                dot: { r: 2 }
            }))
        };
    }, [selectedMetrics, lineColors]);

    const handleMetricClick = useCallback((metricKey) => {
        setSelectedMetrics(prev => {
            const newSelected = prev.includes(metricKey)
                ? prev.filter(m => m !== metricKey)
                : [...prev, metricKey];

            return newSelected.length === 0 ? [metricKey] : newSelected;
        });
    }, []);

    if (!hasData || !data?.rows?.length) {
        return <div>No Search Console data available.</div>;
    }

    return (
        <div id="dashboard-gsc">
            <div className="pdf-page viewport">
                <div className="chart-metrics-wrapper pdf-margin">
                    <div className="chart-metrics-metrics">
                        {loadingState?.isLoading ? (
                            <div className="h-64 flex justify-center items-center">
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <div className="chart-metrics-grid">
                                {Object.entries(stats || {})
                                    .filter(([key]) => displayedChartMetrics.includes(key))
                                    .map(([key, stat]) => (
                                        <div
                                            key={key}
                                            onClick={() => handleMetricClick(key)}
                                            className="metric-card-select-wrapper"
                                        >
                                            <MetricCard
                                                label={metricsConfig[key]?.getLabel?.() ?? metricsConfig[key]?.label ?? key}
                                                value={stat?.value ?? 0}
                                                yearOverYearChange={stat?.yearOverYearChange ?? null}
                                                yearOverYearValue={stat?.yearOverYearValue ?? null}
                                                previousPeriodChange={stat?.previousPeriodChange ?? null}
                                                previousPeriodValue={stat?.previousPeriodValue ?? null}
                                                customPeriodChange={stat?.customPeriodChange ?? null}
                                                customPeriodValue={stat?.customPeriodValue ?? null}
                                                isSelected={selectedMetrics.includes(key)}
                                                multiSelect={true}
                                                component='gsc'
                                                componentKey={key}
                                            />
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div className="chart-metrics-chart">
                        <LineChartTemplate
                            data={chartData}
                            lines={getLineConfig().lines}
                            xAxisDataKey="date"
                            showLegend={true}
                            height={getLineConfig().height}
                            topMargin={50}
                        />
                    </div>
                </div>
            </div>

            <div className='pdf-page viewport'>
                <div className='split-charts border-b border-gray-300'>
                    <div className='split-chart-half'>
                        <div className='gsc-overview'>
                            <div className='gsc-top'>
                                <div className='gsc-top-box'>
                                    <div
                                        className='gsc-top-box-title'
                                        {...useTooltip(setActiveTooltip, 'topQueries')}
                                    >
                                        <span>Top Search Queries</span>
                                        {queryTooltip && activeTooltip && <Tooltip text={queryTooltip} className='tooltip-light' />}
                                    </div>
                                    <div className='gsc-top-box-data'>
                                        <div className='gsc-top-data-header'>
                                            <span className='gsc-header-kw'>Keyword</span>
                                            <span className='gsc-header-result'>Impressions</span>
                                        </div>
                                        {keywordsData.aggregated.impressions.map((keyword, index) => (
                                            <div
                                                key={index}
                                                className={`gsc-top-data-line ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                                            >
                                                <span className='top-data-line-number'>{index + 1}.</span>
                                                <span className='gsc-top-kw'>{keyword.query}</span>
                                                <span className='gsc-top-result'>{formatNumber(keyword.impressions)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='split-chart-half'>
                        <div className='gsc-overview'>
                            <div className='gsc-top'>
                                <div className='gsc-top-box'>
                                    <div
                                        className='gsc-top-box-title'
                                        {...useTooltip(setActiveTooltip, 'topQueries')}
                                    >
                                        <span>Top Pages</span>
                                        {pagesTooltip && activeTooltip && <Tooltip text={pagesTooltip} className='tooltip-light' />}
                                    </div>
                                    <div className='gsc-top-box-data'>
                                        <div className='gsc-top-data-header'>
                                            <span className='gsc-header-kw'>Page</span>
                                            <span className='gsc-header-result'>Impressions</span>
                                        </div>
                                        {pagesData.aggregated.impressions.map((page, index) => (
                                            <div
                                                key={index}
                                                className={`gsc-top-data-line ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                                            >
                                                <span className='top-data-line-number'>{index + 1}.</span>
                                                <span className='gsc-top-kw' title={page.page}>
                                                    {cleanPagePath(page.page)}
                                                </span>
                                                <span className='gsc-top-result'>{formatNumber(page.impressions)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='gsc-keywords'>
                    <h2
                        className='gsc-keywords-title'
                        {...useTooltip(setActiveTooltip, 'highestRanking')}
                    >
                        <span>
                            Highest Ranking Terms for {siteUrl && (
                                <span className='font-semibold'>{siteUrl}</span>
                            )}
                        </span>
                        {rankingTooltip && activeTooltip && <Tooltip text={rankingTooltip} className='tooltip-light' />}
                    </h2>
                    <div className='gsc-kw-table'>
                        <div className='flex flex-wrap gap-1'>
                            {keywordsData.aggregated.topRanked.map((item, index) => (
                                <div
                                    key={index}
                                    className={`inline-flex px-4 py-2 rounded ${index % 2 === 0 ? 'bg-gray-100' : ''}`}
                                    title={`Impressions: ${formatNumber(item.impressions)}`}
                                >
                                    {item.query}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(GSC);
