import { processChartData, getMetricValue, getDimensionValue, formatDuration, formatNumber, parseDate } from '../utils/dataProcessing';

export const metricsConfig = {
    sessions: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                return data.rows.reduce((total, row) => {
                    if (!row?.metricValues?.[2]?.value) return total;
                    return total + (parseInt(row.metricValues[2].value) || 0);
                }, 0);
            } catch (error) {
                console.error('Error calculating sessions:', error);
                return 0;
            }
        },
        label: 'Website Visits',
        getLabel: (isOrganic) => isOrganic ? 'Website Visits (Organic)' : 'Website Visits'
    },
    activeUsers: {
        calculate: (data) => {
            try {
                // Access rows directly if they exist at the top level
                if (data?.active?.rows) {
                    return data.active.rows.reduce((total, row) => {
                        if (!row?.metricValues?.[0]?.value) return total;
                        return total + (parseInt(row.metricValues[0].value) || 0);
                    }, 0);
                }
                return 0;
            } catch (error) {
                console.error('Error calculating activeUsers:', error);
                return 0;
            }
        },
        label: 'Active Users',
        getLabel: (isOrganic) => isOrganic ? 'Active Users (Organic)' : 'Active Users'
    },
    screenPageViews: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                return data.rows.reduce((total, row) => {
                    if (!row?.metricValues?.[1]?.value) return total;
                    return total + (parseInt(row.metricValues[1].value) || 0);
                }, 0);
            } catch (error) {
                console.error('Error calculating screenPageViews:', error);
                return 0;
            }
        },
        label: 'Screen Page Views',
        getLabel: (isOrganic) => isOrganic ? 'Screen Page Views (Organic)' : 'Screen Page Views'
    },
    ecommerceRevenue: {
        calculate: (data) => {
            try {
                if (!data?.ecommerce?.rows) return 0;
                return data.ecommerce.rows.reduce((total, row) => {
                    if (!row?.metricValues?.[0]?.value) return total;
                    return total + (parseFloat(row.metricValues[0].value) || 0);
                }, 0);
            } catch (error) {
                console.error('Error calculating ecommerceRevenue:', error);
                return 0;
            }
        },
        label: 'E-commerce Revenue',
        getLabel: (isOrganic) => isOrganic ? 'E-commerce Revenue (Organic)' : 'E-commerce Revenue'
    },
    sources: {
        calculate: (data) => {
            try {
                if (!data?.sources?.rows) return [];
                return data.sources.rows.map(row => ({
                    name: row?.dimensionValues?.[0]?.value || 'Unknown',
                    value: parseInt(row?.metricValues?.[0]?.value) || 0
                }));
            } catch (error) {
                console.error('Error calculating sources:', error);
                return [];
            }
        },
        label: 'Traffic Sources',
        getLabel: (isOrganic) => isOrganic ? 'Traffic Sources (Organic)' : 'Traffic Sources'
    },
    newUsers: {
        calculate: (data) => {
            try {
                if (!data?.new?.rows) return 0;
                return data.new.rows
                    .filter(row => row?.dimensionValues?.[1]?.value?.toLowerCase() === 'new')
                    .reduce((total, row) => total + (parseInt(row?.metricValues?.[0]?.value) || 0), 0);
            } catch (error) {
                console.error('Error calculating newUsers:', error);
                return 0;
            }
        },
        label: 'New Users',
        getLabel: (isOrganic) => isOrganic ? 'New Users (Organic)' : 'New Users'
    },
    averagePages: {
        calculate: (data) => {
            try {
                if (!data?.pages?.rows) return 0;
                const totalPages = data.pages.rows.reduce((sum, row) => {
                    if (!row?.metricValues?.[0]?.value) return sum;
                    return sum + (parseFloat(row.metricValues[0].value) || 0);
                }, 0);
                return Number((totalPages / (data.pages.rows.length || 1)).toFixed(2));
            } catch (error) {
                console.error('Error calculating averagePages:', error);
                return 0;
            }
        },
        label: 'Pages per Session',
        getLabel: (isOrganic) => isOrganic ? 'Pages per Session (Organic)' : 'Pages per Session',
        formatValue: (value) => Number(value).toFixed(2)
    },
    sessionsPerUser: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                let totalSessions = 0;
                let totalUsers = 0;

                data.rows.forEach(row => {
                    if (row?.metricValues) {
                        totalUsers += parseFloat(row.metricValues[0]?.value || 0);  // activeUsers
                        totalSessions += parseFloat(row.metricValues[2]?.value || 0);  // sessions
                    }
                });

                return Number((totalSessions / (totalUsers || 1)).toFixed(2));
            } catch (error) {
                console.error('Error calculating sessionsPerUser:', error);
                return 0;
            }
        },
        label: 'Sessions per User',
        getLabel: (isOrganic) => isOrganic ? 'Sessions per User (Organic)' : 'Sessions per User',
        formatValue: (value) => Number(value).toFixed(2)
    },
    averageDuration: {
        calculate: (data) => {
            try {
                if (!data?.averageduration?.rows) return 0;
                const totalDuration = data.averageduration.rows.reduce((total, row) => {
                    return total + (parseFloat(row.metricValues[0].value) || 0);
                }, 0);
                return Math.round(totalDuration / (data.averageduration.rows.length || 1));
            } catch (error) {
                console.error('Error calculating averageDuration:', error);
                return 0;
            }
        },
        label: 'Average Duration',
        getLabel: (isOrganic) => isOrganic ? 'Average Duration (Organic)' : 'Average Duration',
        formatValue: (value) => formatDuration(Math.round(value)),
        formatComparisonValue: (value) => formatDuration(Math.round(value))  // Add this line
    },
};

export const chartsConfig = {
    sources: {
        type: 'pie',
        title: ({ isOrganic }) => isOrganic ? 'Traffic Sources (Organic)' : 'Traffic Sources',
        dataKey: 'sourcesData',
        hideWhenOrganic: true,
        getTotalValue: (data, yearOverYearChange, yearOverYearValue, previousPeriodChange, previousPeriodValue, customPeriodChange, customPeriodValue) => ({
            total: data.reduce((sum, item) => sum + (item?.value || 0), 0),
            yearOverYearChange,
            yearOverYearValue,
            previousPeriodChange,
            previousPeriodValue,
            customPeriodChange,
            customPeriodValue
        })
    },
    devices: {
        type: 'pie',
        title: ({ isOrganic }) => isOrganic ? 'Traffic by Devices (Organic)' : 'Traffic by Devices',
        dataKey: 'devicesData',
    },
    newVsReturning: {
        type: 'pie',
        title: ({ isOrganic }) => isOrganic ? 'New vs Returning (Organic)' : 'New vs Returning',
        dataKey: 'newVsReturningData',
    },
    averagePages: {
        type: 'area',
        title: ({ isOrganic }) => isOrganic ? 'Pageviews per Session (Organic)' : 'Pageviews per Session',
        dataKey: 'value',
        sourceKey: 'averagePagesData',
        getTotalValue: (data, yearOverYearChange, yearOverYearValue, previousPeriodChange, previousPeriodValue, customPeriodChange, customPeriodValue) => ({
            total: Number(data.reduce((sum, item) => sum + Number(item?.value || 0), 0) / data.length).toFixed(2),
            yearOverYearChange,
            yearOverYearValue,
            previousPeriodChange,
            previousPeriodValue,
            customPeriodChange,
            customPeriodValue
        })
    },
    sessionsPerUser: {
        type: 'area',
        title: ({ isOrganic }) => isOrganic ? 'Sessions per User (Organic)' : 'Sessions per User',
        dataKey: 'value',
        sourceKey: 'sessionsPerUserData',
        getTotalValue: (data, yearOverYearChange, yearOverYearValue, previousPeriodChange, previousPeriodValue, customPeriodChange, customPeriodValue) => ({
            total: Number(data.reduce((sum, item) => sum + Number(item?.value || 0), 0) / data.length).toFixed(2),
            yearOverYearChange,
            yearOverYearValue,
            previousPeriodChange,
            previousPeriodValue,
            customPeriodChange,
            customPeriodValue
        })
    },
    averageDuration: {
        type: 'area',
        title: ({ isOrganic }) => isOrganic ? 'Average Session Duration (Organic)' : 'Average Session Duration',
        dataKey: 'value',
        sourceKey: 'averageDurationData',
        getTotalValue: (data, yearOverYearChange, yearOverYearValue, previousPeriodChange, previousPeriodValue, customPeriodChange, customPeriodValue) => {
            return {
                total: formatDuration(data.reduce((sum, item) => sum + Number(item?.value || 0), 0) / data.length),
                yearOverYearChange,
                yearOverYearValue: yearOverYearValue != null ? formatDuration(Math.round(yearOverYearValue)) : null,
                previousPeriodChange,
                previousPeriodValue: previousPeriodValue != null ? formatDuration(Math.round(previousPeriodValue)) : null,
                customPeriodChange,
                customPeriodValue: customPeriodValue != null ? formatDuration(Math.round(customPeriodValue)) : null
            };
        }
    },
};

export const displayedChartMetrics = [
    'activeUsers',
    'screenPageViews',
    'sessions',
    'formSubmissions',
    'calls',
    'newUsers',
];

export const transformSourcesData = (inputData) => {
    if (!inputData?.sources?.rows || !inputData.sources.dimensionHeaders || !inputData.sources.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData.sources;

    // Aggregate sessions by channel group
    const aggregatedData = rows.reduce((acc, row) => {
        const channel = getDimensionValue(row, dimensionHeaders, 'sessionPrimaryChannelGroup') || 'Other';
        const sessions = parseInt(getMetricValue(row, metricHeaders, 'sessions')) || 0;

        if (!acc[channel]) acc[channel] = 0;
        acc[channel] += sessions;
        return acc;
    }, {});

    // Convert to array and sort by value
    const sortedData = Object.entries(aggregatedData)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value);

    // Take top 5 sources and combine the rest into "Other"
    const topSources = sortedData.slice(0, 5);
    const otherSources = sortedData.slice(5);

    if (otherSources.length > 0) {
        const otherValue = otherSources.reduce((sum, item) => sum + item.value, 0);
        topSources.push({ name: "Other", value: otherValue });
    }

    return topSources;
};

export const transformDevicesData = (inputData) => {
    if (!inputData?.devices?.rows || !inputData.devices.dimensionHeaders || !inputData.devices.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData.devices;

    const aggregatedData = rows.reduce((acc, row) => {
        const device = getDimensionValue(row, dimensionHeaders, 'deviceCategory') || 'Unknown';
        const sessions = parseInt(getMetricValue(row, metricHeaders, 'sessions')) || 0;

        if (!acc[device]) acc[device] = 0;
        acc[device] += sessions;
        return acc;
    }, {});

    return processChartData(aggregatedData);
};

export const transformNewVsReturningData = (inputData) => {
    if (!inputData?.new?.rows || !inputData.new.dimensionHeaders || !inputData.new.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData.new;

    const aggregatedData = rows.reduce((acc, row) => {
        const userType = getDimensionValue(row, dimensionHeaders, 'newVsReturning') || 'Unknown';
        const sessions = parseInt(getMetricValue(row, metricHeaders, 'sessions')) || 0;

        if (userType &&
            userType.toLowerCase() !== "not set" &&
            userType.toLowerCase() !== "(not set)" &&
            !userType.toLowerCase().includes("not set")) {
            acc[userType] = (acc[userType] || 0) + sessions;
        }
        return acc;
    }, {});

    return processChartData(aggregatedData);
};

export const transformNewUsersData = (inputData) => {
    if (!inputData?.new?.rows || !inputData.new.dimensionHeaders || !inputData.new.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData.new;

    const dailyData = rows
        .filter(row => row.dimensionValues[1].value.toLowerCase() === 'new')
        .reduce((acc, row) => {
            const dateStr = getDimensionValue(row, dimensionHeaders, 'date');
            if (!dateStr) return acc;

            const formattedDate = dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

            if (!acc[formattedDate]) {
                acc[formattedDate] = {
                    date: formattedDate,
                    value: 0
                };
            }

            acc[formattedDate].value += parseInt(getMetricValue(row, metricHeaders, 'totalUsers')) || 0;
            return acc;
        }, {});

    return Object.values(dailyData)
        .sort((a, b) => a.date.localeCompare(b.date));
};

export const transformAveragePagesData = (inputData) => {
    if (!inputData?.pages?.rows || !inputData.pages.dimensionHeaders || !inputData.pages.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData.pages;

    const dailyData = rows.reduce((acc, row) => {
        const dateStr = getDimensionValue(row, dimensionHeaders, 'date');
        if (!dateStr) return acc;

        const formattedDate = dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

        if (!acc[formattedDate]) {
            acc[formattedDate] = {
                date: formattedDate,
                totalValues: 0,
                count: 0
            };
        }

        const pageViewsPerSession = parseFloat(getMetricValue(row, metricHeaders, 'screenPageViewsPerSession')) || 0;
        acc[formattedDate].totalValues += pageViewsPerSession;
        acc[formattedDate].count += 1;

        return acc;
    }, {});

    return Object.values(dailyData)
        .map(({ date, totalValues, count }) => ({
            date,
            value: count ? Number((totalValues / count).toFixed(2)) : 0
        }))
        .sort((a, b) => a.date.localeCompare(b.date));
};

export const transformSessionsPerUserData = (inputData) => {
    if (!inputData?.rows || !inputData.dimensionHeaders || !inputData.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData;

    const dailyData = rows.reduce((acc, row) => {
        const dateStr = getDimensionValue(row, dimensionHeaders, 'date');
        if (!dateStr) return acc;

        const formattedDate = dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

        if (!acc[formattedDate]) {
            acc[formattedDate] = {
                date: formattedDate,
                sessions: 0,
                users: 0
            };
        }

        acc[formattedDate].sessions += parseInt(row.metricValues[2]?.value) || 0;
        acc[formattedDate].users += parseInt(row.metricValues[0]?.value) || 0;
        return acc;
    }, {});

    return Object.values(dailyData)
        .map(({ date, sessions, users }) => ({
            date,
            value: users ? Number((sessions / users).toFixed(2)) : 0
        }))
        .sort((a, b) => a.date.localeCompare(b.date));
};

export const transformAverageDurationData = (inputData) => {
    if (!inputData?.averageduration?.rows || !inputData.averageduration.dimensionHeaders || !inputData.averageduration.metricHeaders) return [];

    const { rows, dimensionHeaders, metricHeaders } = inputData.averageduration;

    const dailyData = rows.reduce((acc, row) => {
        const dateStr = getDimensionValue(row, dimensionHeaders, 'date');
        if (!dateStr) return acc;

        const formattedDate = dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

        if (!acc[formattedDate]) {
            acc[formattedDate] = {
                date: formattedDate,
                value: Math.round(parseFloat(getMetricValue(row, metricHeaders, 'averageSessionDuration')) || 0)
            };
        }

        return acc;
    }, {});

    return Object.values(dailyData)
        .sort((a, b) => a.date.localeCompare(b.date));
};

export const aggregateGA4Data = (data, aggregationType) => {
    // Early return if data is empty or incomplete
    if (!data?.dimensionHeaders?.length || !data?.metricHeaders?.length) {
        return [];
    }

    const aggregateDataset = (dataset, type) => {
        // Skip aggregation for invalid datasets silently
        if (!dataset?.rows?.length || !dataset?.dimensionHeaders?.length || !dataset?.metricHeaders?.length) {
            return [];
        }

        try {
            const { rows, dimensionHeaders, metricHeaders } = dataset;
            const aggregatedData = new Map();

            rows.forEach(row => {
                const dateString = getDimensionValue(row, dimensionHeaders, 'date');
                if (!dateString) return;

                const date = new Date(dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
                let key;

                switch (type) {
                    case 'week':
                        const weekStart = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - date.getUTCDay()));
                        key = weekStart.toISOString().split('T')[0];
                        break;
                    case 'month':
                        key = `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-01`;
                        break;
                    default:
                        key = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
                }

                if (!aggregatedData.has(key)) {
                    aggregatedData.set(key, {
                        date: new Date(key),
                        activeUsers: 0,
                        screenPageViews: 0,
                        sessions: 0,
                    });
                }

                const aggregatedRow = aggregatedData.get(key);
                aggregatedRow.activeUsers += parseInt(getMetricValue(row, metricHeaders, 'activeUsers')) || 0;
                aggregatedRow.screenPageViews += parseInt(getMetricValue(row, metricHeaders, 'screenPageViews')) || 0;
                aggregatedRow.sessions += parseInt(getMetricValue(row, metricHeaders, 'sessions')) || 0;
            });

            return Array.from(aggregatedData.values())
                .sort((a, b) => a.date - b.date);

        } catch (error) {
            return [];
        }
    };

    try {
        const currentAggregated = aggregateDataset(data, aggregationType);
        const previousAggregated = data.previousPeriod ? aggregateDataset(data.previousPeriod, aggregationType) : [];

        return currentAggregated.map((currentItem, index) => ({
            ...currentItem,
            previous: previousAggregated[index] ? {
                activeUsers: previousAggregated[index].activeUsers,
                sessions: previousAggregated[index].sessions
            } : null
        }));
    } catch (error) {
        return [];
    }
};

const transformTrafficData = (data) => {
    if (!data?.rows) return [];

    // Create a map to store daily data
    const dailyData = {};

    // Process main metrics
    data.rows.forEach(row => {
        const dateStr = getDimensionValue(row, data.dimensionHeaders, 'date');
        const dateKey = dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

        if (!dailyData[dateKey]) {
            dailyData[dateKey] = {
                date: dateKey,
                activeUsers: 0,
                screenPageViews: 0,
                sessions: 0,
                formSubmissions: 0,
                calls: 0,
                newUsers: 0
            };
        }

        dailyData[dateKey].activeUsers += parseInt(getMetricValue(row, data.metricHeaders, 'activeUsers')) || 0;
        dailyData[dateKey].screenPageViews += parseInt(getMetricValue(row, data.metricHeaders, 'screenPageViews')) || 0;
        dailyData[dateKey].sessions += parseInt(getMetricValue(row, data.metricHeaders, 'sessions')) || 0;
        dailyData[dateKey].formSubmissions += parseInt(getMetricValue(row, data.metricHeaders, 'formSubmissions')) || 0;
        dailyData[dateKey].calls += parseInt(getMetricValue(row, data.metricHeaders, 'calls')) || 0;
    });

    // Process new users data separately
    if (data.new?.rows) {
        data.new.rows.forEach(row => {
            const dateStr = getDimensionValue(row, data.new.dimensionHeaders, 'date');
            const dateKey = dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
            const userType = getDimensionValue(row, data.new.dimensionHeaders, 'newVsReturning');

            if (dailyData[dateKey] && userType?.toLowerCase() === 'new') {
                dailyData[dateKey].newUsers += parseInt(getMetricValue(row, data.new.metricHeaders, 'totalUsers')) || 0;
            }
        });
    }

    // Sort by date
    return Object.values(dailyData)
        .sort((a, b) => a.date.localeCompare(b.date));
};

export const transformGA4Data = (data, yearOverYearData, previousPeriodData, customPeriodData) => {
    if (!data || !data.dimensionHeaders || !data.metricHeaders) return {};

    // Transform main traffic data (chart data)
    const revenueData = transformTrafficData(data);
    const yearOverYearTrafficData = yearOverYearData ? transformTrafficData(yearOverYearData) : [];
    const previousPeriodTrafficData = previousPeriodData ? transformTrafficData(previousPeriodData) : [];
    const customPeriodTrafficData = customPeriodData ? transformTrafficData(customPeriodData) : [];

    // Transform other chart data
    const sourcesData = transformSourcesData(data);
    const devicesData = transformDevicesData(data);
    const newVsReturningData = transformNewVsReturningData(data);
    const newUsersData = transformNewUsersData(data);
    const averagePagesData = transformAveragePagesData(data);
    const sessionsPerUserData = transformSessionsPerUserData(data);
    const averageDurationData = transformAverageDurationData(data);

    return {
        chartData: revenueData.map((currentItem, index) => ({
            ...currentItem,
            yearOverYear: yearOverYearTrafficData[index] || null,
            previousPeriod: previousPeriodTrafficData[index] || null,
            customPeriod: customPeriodTrafficData[index] || null
        })),
        sourcesData,
        devicesData,
        newVsReturningData,
        newUsersData,
        averagePagesData,
        sessionsPerUserData,
        averageDurationData,
    };
};
