export const getTooltip = (component, key) => {
    return tooltips[component]?.[key];
};

export const useTooltip = (setTooltipVisibility, tooltipKey) => ({
    onMouseEnter: () => setTooltipVisibility(tooltipKey),
    onMouseLeave: () => setTooltipVisibility(null)
});

export const tooltips = {
    snapshot: {
        'Contact Forms' : 'Contact form submissions from your website.',
        'Phone Calls from Google' : 'The number of times users clicked on your phone number to call your business directly from your Google Business Profile. This tracks potential customers who chose to contact you by phone after viewing your listing.'
    },
    ga4: {
        'sessions': 'Formally called Sessions in Google. A session is a period of time a user is actively engaged with your website or app. Sessions end after 30 minutes of inactivity or at midnight.',
        'activeUsers': 'The number of distinct users who visited your site or app during a specific time period. This counts each unique visitor only once, regardless of how many sessions they initiated.',
        'screenPageViews': 'The total number of times users viewed a specific page or screen in your app or website. Multiple views of the same page during a single session are counted separately.',
        'newUsers': 'First-time visitors who have never interacted with your site or app before. This tracks when a user visits your property for the first time on a specific device or browser.'
    },
    gsc: {
        'impressions': 'The number of times your website appeared in Google search results that users could see.',
        'clicks': 'The number of times users clicked on your website link in Google search results. This measures actual traffic driven to your site from Google\'s search engine.',
        'topQueries': 'A ranked list of keywords that triggered your website to appear in search results, sorted by impression count. This shows which search terms are most frequently causing your site to display in Google results',
        'topPages': 'A ranked list of your website pages that appeared most frequently in Google search results. This highlights which content on your site is most visible in Google search, sorted by total impressions.',
        'highestRanking': 'An extended collection of the highest ranking keywords/queries that triggered your website to appear in search results.',
    },
    gbp: {
        'TOTAL_GBP_INTERACTIONS': 'A combination of all GBP actions for your business.',
        'IMPRESSIONS_SEARCH': 'The number of times your business listing appeared in Google Search results. This counts each time users see your business information when searching for related terms or your business name directly.',
        'IMPRESSIONS_MAPS': 'The number of times your business listing appeared in Google Maps results. This measures visibility when users search for businesses like yours in the Maps application or maps.google.com.',
        'WEBSITE_CLICKS': 'The number of times users clicked on your website link from your Google Business Profile listing. This tracks how often potential customers chose to visit your website to learn more about your business after viewing your profile.',
        'BUSINESS_DIRECTION_REQUESTS': 'The number of times users clicked to get directions to your business location through Google Maps. This indicates intent to visit your physical location after finding your business listing.',
        'CALL_CLICKS': 'The number of times users clicked on your phone number to call your business directly from your Google Business Profile. This tracks potential customers who chose to contact you by phone after viewing your listing.',
    }
};
